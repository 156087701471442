import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { makeStyles, MenuItem } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import { TextField, Button } from '@avangard/ui/core';
import { white } from '@avangard/ui/colors';

import { CommonHelper } from '@helpers';
import { useCurrentUser } from '@modules/auth/hooks';
import { useLapsWithCertificates } from '@modules/lap/hooks';

import type { Theme } from '@material-ui/core';

type DownloadCertificateProps = {
    logoutLoading: boolean;
};

const useSelectStyles = makeStyles<Theme>({
    select: {
        color: white[100],
    },
    icon: {
        color: white[100],
    },
});

const useInputStyles = makeStyles<Theme>({
    notchedOutline: {
        borderColor: white[100],
    },
});

const Root = styled.div`
    min-width: 185px;
    margin-inline-end: 16px;
`;

const CertificateButton = styled(Button)`
    color: ${white[100]};
`;

const downloadIcon = <GetApp style={{ fontSize: 16 }} />;

const DownloadCertificate = (props: DownloadCertificateProps): React.ReactElement | null => {
    const { logoutLoading } = props;

    const { t } = useTranslation();

    const selectClasses = useSelectStyles();
    const inputClasses = useInputStyles();

    const { currentUser } = useCurrentUser();

    const { lapsWithCertificates, lapsWithCertificatesCount } = useLapsWithCertificates({
        skip: !currentUser.isSchool(),
    });

    const handleChooseCertificateForDownload = async (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        const lapWithCertificate = lapsWithCertificates.find(
            entity => entity.lap.id === event.target.value,
        );

        if (!lapWithCertificate) {
            return;
        }

        CommonHelper.downloadUrl(lapWithCertificate.certificatesUrl);
    };

    const handleDownloadCertificate = async () =>
        CommonHelper.downloadUrl(currentUser.student.olCourseCertificateUrl);

    if (currentUser.isSchool() && lapsWithCertificatesCount > 0) {
        return (
            <Root>
                <TextField
                    select
                    fullWidth
                    value=''
                    onChange={handleChooseCertificateForDownload}
                    SelectProps={{ classes: selectClasses }}
                    InputProps={{ classes: inputClasses }}
                >
                    <MenuItem value=''>{t('common:actions.download_certificate')}</MenuItem>

                    {lapsWithCertificates.map(lapWithCertificate => (
                        <MenuItem key={lapWithCertificate.lap.id} value={lapWithCertificate.lap.id}>
                            {lapWithCertificate.lap.getFormattedName(false)}
                        </MenuItem>
                    ))}
                </TextField>
            </Root>
        );
    }

    const hasCertificate = currentUser.isStudent() && !!currentUser.student.olCourseCertificateUrl;

    if (hasCertificate) {
        return (
            <Root>
                <CertificateButton
                    fullWidth
                    variant='text'
                    endIcon={downloadIcon}
                    disabled={logoutLoading}
                    onClick={handleDownloadCertificate}
                >
                    {t('common:actions.download_certificate')}
                </CertificateButton>
            </Root>
        );
    }

    return null;
};

export { DownloadCertificate };
