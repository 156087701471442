import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { OlCourse, OlCourseTest } from '@modules/types/graphql';

export type Entity = Partial<OlCourse> | null;

export type CourseTest = Omit<OlCourseTest, '__typename'>;

@DecribeEntity('CourseEntity')
class CourseEntity extends BaseEntity {
    id: string;
    name: string;
    certificateUrl: string;
    test: CourseTest;
    testAvailable: boolean;
    subjectsCount: number;
    testsCount: number;
    isAvailable: boolean;

    constructor(course: Entity) {
        super(course);

        this.id = course?.id ?? '';
        this.name = course?.name ?? '';
        this.certificateUrl = course?.certificateUrl ?? '';
        this.testAvailable = course?.testAvailable ?? false;
        this.subjectsCount = course?.subjectsCount ?? 0;
        this.testsCount = course?.testsCount ?? 0;
        this.isAvailable = course?.isAvailable ?? false;
        this.test = course?.test ?? {
            duration: 0,
            questionsCount: 0,
        };
    }

    exists() {
        const exists = this.id !== '';

        return exists;
    }

    hasQuestions() {
        const hasQuestions = this.test.questionsCount !== 0;

        return hasQuestions;
    }

    hasTime() {
        const hasTime = this.test.duration !== 0;

        return hasTime;
    }

    isComplete() {
        const result = this.certificateUrl !== '';

        return result;
    }
}

export { CourseEntity };
