import format from 'date-fns/format';

import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';
import { i18n, getLocaleForDateFns } from '@lib/i18n';
import { StringHelper } from '@helpers';
import { programTypeTranslates } from '@modules/lap/constants';
import { CourseEntity } from '@modules/courses/entities';
import { LapProgramType, Platform } from '@modules/types/graphql';

import type { Lap } from '@modules/types/graphql';

export type LapRawEntity = Partial<Lap> | null;

@DecribeEntity('LapEntity')
class LapEntity extends BaseEntity {
    id: string;
    name: string;
    from: string;
    to: string;
    platform: Platform | null;
    isCurrent: boolean;
    programType: LapProgramType | null;
    isFree: boolean;

    @Relation(() => CourseEntity)
    course: CourseEntity;

    constructor(lap: LapRawEntity) {
        super(lap);

        this.id = lap?.id ?? '';
        this.name = lap?.name ?? '';
        this.from = lap?.from ?? '';
        this.to = lap?.to ?? '';
        this.platform = lap?.platform ?? null;
        this.isCurrent = lap?.isCurrent ?? false;
        this.programType = lap?.programType ?? null;
        this.isFree = lap?.isFree ?? false;
    }

    exists() {
        const exists = this.id !== '';

        return exists;
    }

    getProgramType() {
        if (!this.programType) {
            return null;
        }

        const programType = i18n.t(programTypeTranslates[this.programType]);

        return programType;
    }

    getPlatformTitle() {
        if (!this.platform) {
            return null;
        }

        const title = StringHelper.capitalizeFirstLetter(this.platform);

        return title;
    }

    getFullDate() {
        const fromDate = this.from ? new Date(this.from) : null;
        const toDate = this.to ? new Date(this.to) : null;

        const locale = getLocaleForDateFns();

        const from = fromDate ? format(fromDate, 'dd.MM.yyyy', { locale }) : '//';
        const to = toDate ? format(toDate, 'dd.MM.yyyy', { locale }) : '//';

        const date = `${from} - ${to}`;

        return date;
    }

    getDate(dateString: string): Date | null {
        if (!dateString) {
            return null;
        }

        const date = new Date(dateString);

        return date;
    }

    nowDateBefore() {
        if (!this.from) {
            return false;
        }

        const now = new Date().getTime();
        const from = new Date(this.from).getTime();

        return now < from;
    }

    nowDateIncluded() {
        if (!this.from || !this.to) {
            return false;
        }

        const now = new Date().getTime();
        const from = new Date(this.from).getTime();
        const to = new Date(this.to).getTime();

        return from <= now && now <= to;
    }

    nowDateAfter() {
        if (!this.to) {
            return false;
        }

        const now = new Date().getTime();
        const to = new Date(this.to).getTime();

        return now > to;
    }

    hasCurrentDateInLap() {
        return this.nowDateIncluded() || this.nowDateAfter();
    }

    isActiveLap(currentLap: LapEntity) {
        if (!currentLap.exists() || !this.id) {
            return false;
        }

        const isActiveLap = currentLap.id === this.id;

        return isActiveLap;
    }

    isCenterLap() {
        const isCenterLap = this.platform === Platform.center;

        return isCenterLap;
    }

    isOnlineLap() {
        const isOnlineLap = this.platform === Platform.online;

        return isOnlineLap;
    }

    getFormattedName(withPlatform: boolean = true) {
        const platform = this.getPlatformTitle();
        const courseName = this.course.name;
        const platformTitle = this.getProgramType();

        let name = this.name;

        if (platform && withPlatform) {
            name += ` • ${platform}`;
        }

        if (courseName || platformTitle) {
            name += ` • ${courseName || platformTitle}`;
        }

        return name.trim();
    }
}

export { LapEntity };
