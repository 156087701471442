import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Tabs } from '@avangard/ui/core';
import { white } from '@avangard/ui/colors';

import type { TabsProps } from '@avangard/ui/core';

const useTabsStyles = makeStyles(theme => ({
    root: {
        width: '17%',
        maxWidth: 265,

        [theme.breakpoints.down('md')]: {
            width: '100%',
            padding: '0 16px',
            marginBottom: '24px',
            maxWidth: 'none',
            borderBottom: `1px solid ${white[70]}`,
        },
    },
}));

const TabsEdit = (props: TabsProps): React.ReactElement => {
    const classes = useTabsStyles();

    return <Tabs classes={classes} {...props} />;
};

export { TabsEdit };
