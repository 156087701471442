import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as BaseLink } from '@reach/router';

import type { LinkProps } from '@reach/router';

const Link = React.forwardRef<HTMLAnchorElement, LinkProps<{}>>((props, ref) => {
    const { to, ...otherProps } = props;

    const { i18n } = useTranslation();

    const toWithLang = '/' + i18n.language + to;

    return <BaseLink {...otherProps} ref={ref} to={toWithLang} />;
});

Link.displayName = 'Link';

export { Link };
