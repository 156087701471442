// TODO: set correct types
const toPromise = (observable: any) => {
    let completed = false;

    return new Promise((resolve, reject) => {
        observable.subscribe({
            next: (data: any) => {
                if (completed) {
                    console.log(
                        `Promise Wrapper does not support multiple results from Observable`,
                    );
                } else {
                    completed = true;
                    resolve(data);
                }
            },
            error: reject,
        });
    });
};

export { toPromise };
