import { EntityList } from '@lib/entity';
import { RegionEntity } from './region.entity';

import type { Entity } from './region.entity';

class RegionEntityList extends EntityList<RegionEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(RegionEntity, items, count);
    }
}

export { RegionEntityList };
