import React from 'react';
import styled from 'styled-components';

type ListToolbarProps = {
    children: React.ReactNode;
    className?: string;
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    margin-bottom: 28px;
`;

const ListToolbar = (props: ListToolbarProps): React.ReactElement => {
    const { children, ...otherProps } = props;

    return <Root {...otherProps}>{children}</Root>;
};

export { ListToolbar };
