import Cookie from 'js-cookie';
import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { RestLink } from 'apollo-link-rest';

import { GRAPHQL_URL, REST_URL } from '@config/environment';
import { TokenSession } from '@lib/token';
import { SystemHelper } from '@helpers';
import { RefreshTokenMutation } from '@modules/auth/graphql';
import { toPromise } from './utils';

import type {
    ApolloClientOptions as BaseApolloClientOptions,
    NormalizedCacheObject,
} from '@apollo/client';
import type { SetTokenSessionPayload } from '@lib/token';

type ApolloClientOptions = Omit<BaseApolloClientOptions<NormalizedCacheObject>, 'cache'>;

const isDev = SystemHelper.isDev();

const noopPromise = new Promise<void>(resolve => resolve());

const createApolloClient = (options: ApolloClientOptions = {}) => {
    const graphqlUri = GRAPHQL_URL;
    const restUri = REST_URL;

    const httpLink = new HttpLink({ uri: graphqlUri });

    const authHttpLink = setContext((_, prevContext) => {
        const headers = { ...prevContext.headers };

        const token = Cookie.get('accessToken');

        const context = {
            ...prevContext,
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : null,
            },
        };

        return context;
    });

    const tokenRefreshLink = new TokenRefreshLink<SetTokenSessionPayload>({
        accessTokenField: 'tokens',

        isTokenValidOrUndefined: () =>
            !TokenSession.getCurrentSession().getAccessToken().isExpired() ||
            !TokenSession.getCurrentSession().hasTokens(),

        fetchAccessToken: (): Promise<any> => {
            const refreshToken = TokenSession.getCurrentSession().getRefreshToken();

            if (!refreshToken.issetToken()) {
                return noopPromise;
            }

            const promise = toPromise(
                HttpLink.execute(httpLink, {
                    query: RefreshTokenMutation,
                    variables: {
                        refreshToken: refreshToken.getToken(),
                    },
                }),
            );

            return promise;
        },

        handleFetch: (payload: SetTokenSessionPayload) => {
            TokenSession.setCurrentSession(payload);
        },

        handleResponse: () => (response: any) => {
            const responseData = response?.data?.refreshToken;

            if (responseData?.accessToken && responseData?.refreshToken) {
                return {
                    tokens: {
                        accessToken: responseData.accessToken,
                        refreshToken: responseData.refreshToken,
                    },
                };
            }

            return {
                tokens: {},
            };
        },

        handleError: error => {
            TokenSession.destroyCurrentSession();
        },
    });

    const restLink = new RestLink({
        uri: restUri,
        bodySerializers: {
            fileEncode: (data: any, headers: Headers) => {
                const formData = new FormData();

                formData.append('file', data, data.name);

                return { body: formData, headers };
            },
        },
    });

    // TODO: add to ApolloLink.from when backend can resolve it
    const batchHttpLink = new BatchHttpLink({
        uri: graphqlUri,
        batchMax: 6,
    });

    const link = ApolloLink.from([authHttpLink, restLink, tokenRefreshLink, httpLink]);

    const apolloClient = new ApolloClient({
        link,
        cache: new InMemoryCache(),
        connectToDevTools: isDev,
        ...options,
    });

    return apolloClient;
};

export { createApolloClient };
