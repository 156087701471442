import { useSnackbar } from 'notistack';

import type { SnackbarMessage, OptionsObject } from 'notistack';

const useEnqueueStacks = () => {
    const { enqueueSnackbar } = useSnackbar();

    const enqueueDefault = (message: SnackbarMessage, options?: OptionsObject) =>
        enqueueSnackbar(message, { variant: 'default', ...options });

    const enqueueError = (message: SnackbarMessage, options?: OptionsObject) =>
        enqueueSnackbar(message, { variant: 'error', ...options });

    const enqueueSuccess = (message: SnackbarMessage, options?: OptionsObject) =>
        enqueueSnackbar(message, { variant: 'success', ...options });

    const enqueueWarning = (message: SnackbarMessage, options?: OptionsObject) =>
        enqueueSnackbar(message, { variant: 'warning', ...options });

    const enqueueInfo = (message: SnackbarMessage, options?: OptionsObject) =>
        enqueueSnackbar(message, { variant: 'info', ...options });

    return {
        enqueueDefault,
        enqueueError,
        enqueueSuccess,
        enqueueWarning,
        enqueueInfo,
    };
};

export { useEnqueueStacks };
