import React from 'react';

import { PublicRoute, PrivateRoute } from '@modules/auth/moleculas';

import type { Route } from '@config/routes';

const renderRoutes = (routes: Route[]) => {
    const rendered = routes.map(route => {
        const { private: isPrivate, children, ...otherRoute } = route;

        const RouteComponent = isPrivate ? PrivateRoute : PublicRoute;

        return (
            <RouteComponent key={otherRoute.name} private={isPrivate} {...otherRoute}>
                {children}
            </RouteComponent>
        );
    });

    return rendered;
};

export { renderRoutes };
