import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme, Hidden } from '@material-ui/core';
import { breakpoints } from '@avangard/ui/utils';
import { red, white } from '@avangard/ui/colors';

import { Logo } from '@modules/layout/icons';
import { ChangeLanguage } from '@modules/layout/moleculas';

type AuthLayoutProps = {
    children?: React.ReactElement;
};

const Root = styled.main`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    ${breakpoints.down('xs')} {
        flex-direction: column;
        justify-content: initial;
    }
`;

const Screen = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
`;

const WelcomeScreen = styled(Screen)`
    flex: 0 0 auto;
    min-width: 266px;
    width: 35%;
    background-image: url('/images/main-background.jpg');

    > h1 {
        max-width: 230px;
        margin-top: 2em;
        color: ${white[100]};
        font-size: 1.6rem;
        font-weight: 400;
        text-align: center;
        line-height: 22px;

        ${breakpoints.down('xs')} {
            margin: 0;
        }
    }

    ${breakpoints.down('xs')} {
        width: 100%;
        height: 80px;
    }
`;

const WelcomeLogo = styled(Logo)`
    max-width: 170px;
    max-height: 170px;

    ${breakpoints.down('xs')} {
        max-height: 100%;
        max-width: none;
        padding: 10px 0;
    }
`;

const SigninScreen = styled(Screen)`
    position: relative;
    width: 100%;
    flex: 1 1 auto;

    ${breakpoints.down('xs')} {
        height: 100%;
        padding: 10px;

        position: unset;
    }
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 8px;
`;

const Content = styled.div``;

const VPD = styled.a`
    background: ${red[100]};
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: ${white[100]};
    font-size: 1.4em;
    font-weight: 600;

    ${breakpoints.between(600, 900)} {
        padding-right: 110px;
    }
    
    ${breakpoints.between(600, 740)} {
        font-size: 1.1em;
    }
    
    ${breakpoints.down('xs')} {
        border-radius: 0;
        top: 80px;
        font-size: 1.1em;
    }
    
    span {
        margin-left: 12px;
        font-size: 0.9em;
        display: flex;
        align-items: center;
        padding: 4px 8px;
        justify-content: center;
        border-radius: 12px;
        background: ${white[100]};
        color: ${red[100]};
    }
`;

const AuthLayout = (props: AuthLayoutProps): React.ReactElement => {
    const { children } = props;

    const { t } = useTranslation(['common']);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });

    return (
        <Root>
            <Wrapper>
                <WelcomeScreen>
                    <WelcomeLogo />

                    <Hidden xsDown>
                        <h1>{t('common:blocks.common.main')}</h1>
                    </Hidden>
                </WelcomeScreen>

                <SigninScreen>
                    <Header>
                        <VPD href="https://diktant.avangard-online.ru/">
                            Военно-патриотический диктант 2024
                            <span>Перейти</span>
                        </VPD>
                        {/*TODO: когда выпилим ссылку VPD нужно вернуть сюда isMobile ? 'white' : 'black' */}
                        <ChangeLanguage color="white" />
                    </Header>

                    <Content>{children}</Content>
                </SigninScreen>
            </Wrapper>
        </Root>
    );
};

export { AuthLayout };
