import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { withStyles, InputAdornment } from '@material-ui/core';
import { Person, Lock } from '@material-ui/icons';
import { black } from '@avangard/ui/colors';
import { TextField, Button } from '@avangard/ui/core';

import { TokenSession } from '@lib/token';
import { useNavigate, createRouteUrl } from '@lib/routing';
import { routes } from '@config/routes';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { LoginRequest } from '@modules/auth/requests';
import { LoginMutation } from '@modules/auth/graphql';
import { AgreeCheckbox } from '@modules/auth/atoms';

import type { LoginMutationType, LoginMutationVariables } from '@modules/types/graphql';

type SigninFormProps = {
    className?: string;
};

const StyledTextField = withStyles({
    root: {
        marginBottom: 24,
    },
})(TextField);

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    width: 100%;
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    > * {
        width: 100%;
    }

    > h3 {
        color: ${black[80]};
        font-size: 2.8rem;
        font-weight: 500;
    }
`;

const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 24px;
`;

const StyledForm = styled(Form)`
    width: 100%;
`;

const StyledFormRowWithMargin = styled(FormStyled.FormRowWithMargin)`
    &:not(:last-of-type) {
        margin-bottom: 12px;
    }
`;

const personIcon = <Person style={{ fontSize: 16 }} />;
const lockIcon = <Lock style={{ fontSize: 16 }} />;

const SigninForm = (props: SigninFormProps): React.ReactElement => {
    const { t } = useTranslation(['common', 'errors', 'auth']);

    const navigate = useNavigate();

    const [login] = useMutation<LoginMutationType, LoginMutationVariables>(LoginMutation);

    return (
        <Root {...props}>
            <Header>
                <h3>{t('auth:blocks.titles.auth')}</h3>
            </Header>

            <Main>
                <ExtendedFormik
                    validateOnChange={false}
                    validateOnBlur={false}
                    validationSchema={yup.object({
                        login: yup.string().required(t('errors:login.required') ?? ''),
                        password: yup.string().required(t('errors:password.typing') ?? ''),
                        agree: yup.bool().isTrue().required(),
                    })}
                    initialValues={{
                        login: '',
                        password: '',
                        agree: false,
                    }}
                    onSubmit={async values => {
                        try {
                            const variables = new LoginRequest(values);

                            const { data: loginData } = await login({
                                variables,
                            });

                            if (loginData?.login?.accessToken && loginData?.login?.refreshToken) {
                                TokenSession.setCurrentSession(loginData.login);

                                await navigate(routes.index.path);
                            }
                        } catch (e) {
                            throw e;
                        }
                    }}
                >
                    {formikProps => {
                        const { values, errors, handleChange, handleBlur, isSubmitting } =
                            formikProps;

                        const handleClickRedirectToReset = (): void => {
                            const route = createRouteUrl(routes.forgotPassword);

                            navigate(route, { state: { login: values.login } });
                        };

                        const handleClickRedirectToRegister = (): void => {
                            const route = createRouteUrl(routes.register);

                            navigate(route, { state: { email: values.login } });
                        };

                        return (
                            <StyledForm>
                                <StyledTextField
                                    fullWidth
                                    id='login'
                                    label={t('common:form.labels.username')}
                                    placeholder={t('common:form.placeholder.username') ?? ''}
                                    value={values.login}
                                    error={!!errors.login}
                                    helperText={errors.login}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                {personIcon}
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <StyledTextField
                                    fullWidth
                                    id='password'
                                    type='password'
                                    label={t('common:form.labels.password')}
                                    placeholder={t('common:form.placeholder.password') ?? ''}
                                    value={values.password}
                                    error={!!errors.password}
                                    helperText={errors.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                {lockIcon}
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <AgreeCheckbox
                                    name='agree'
                                    onChange={handleChange}
                                    hasError={!!errors.agree}
                                />

                                <StyledFormRowWithMargin>
                                    <Button fullWidth type='submit' loading={isSubmitting}>
                                        {t('common:actions.enter')}
                                    </Button>
                                </StyledFormRowWithMargin>

                                <StyledFormRowWithMargin>
                                    <Button
                                        fullWidth
                                        variant='text'
                                        type='button'
                                        disabled={isSubmitting}
                                        onClick={handleClickRedirectToReset}
                                    >
                                        {t('common:form.labels.forget_password')}
                                    </Button>
                                </StyledFormRowWithMargin>
                                {/* TODO: registration is temporarily disabled */}
                                {/*<StyledFormRowWithMargin>*/}
                                {/*    <Button*/}
                                {/*        fullWidth*/}
                                {/*        variant='text'*/}
                                {/*        type='button'*/}
                                {/*        // disabled={isSubmitting}*/}
                                {/*         TODO: registration is temporarily disabled */}
                                {/*        disabled={true}*/}
                                {/*        onClick={handleClickRedirectToRegister}*/}
                                {/*    >*/}
                                {/*        {t('common:actions.register')}*/}
                                {/*    </Button>*/}
                                {/*</StyledFormRowWithMargin>*/}
                            </StyledForm>
                        );
                    }}
                </ExtendedFormik>
            </Main>
        </Root>
    );
};

export { SigninForm };
