import React from 'react';
import _noop from 'lodash/noop';
import { useTranslation } from 'react-i18next';

import type { TextDirection, TextDirectionProviderValue } from './types';

type TextDirectionProviderProps = {
    children: React.ReactNode;
};

const TextDirectionContext = React.createContext<TextDirectionProviderValue>({
    direction: 'ltr',
    onChangeDirection: _noop,
    isRtl: () => false,
    isLtr: () => false,
});

const TextDirectionProvider = (props: TextDirectionProviderProps) => {
    const { children } = props;

    const { i18n } = useTranslation();

    const [direction, setDirection] = React.useState<TextDirection>('ltr');

    const handleChangeDirection = (direction: TextDirection) => setDirection(direction);

    React.useEffect(() => {
        const [htmlElement] = document.getElementsByTagName('html');
        const [bodyElement] = document.getElementsByTagName('body');

        if (htmlElement) {
            htmlElement.setAttribute('dir', direction);
        }

        if (bodyElement) {
            bodyElement.setAttribute('dir', direction);
        }
    }, [direction]);

    React.useEffect(() => {
        const resultDirection = i18n.language === 'ar' ? 'rtl' : 'ltr';

        handleChangeDirection(resultDirection);
    }, [i18n.language]);

    const contextValue = {
        direction,
        onChangeDirection: handleChangeDirection,
        isRtl: () => direction === 'rtl',
        isLtr: () => direction === 'ltr',
    };

    return (
        <TextDirectionContext.Provider value={contextValue}>
            {children}
        </TextDirectionContext.Provider>
    );
};

export { TextDirectionContext, TextDirectionProvider };
