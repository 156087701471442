import { EntityList } from '@lib/entity';
import { PlatformAccessItemEntity } from './platform-access-item.entity';

import type { Entity } from './platform-access-item.entity';

class PlatformAccessItemEntityList extends EntityList<PlatformAccessItemEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(PlatformAccessItemEntity, items, count);
    }

    getSorted() {
        return [...this.entities].sort((a, b) => b.platform.localeCompare(a.platform));
    }
}

export { PlatformAccessItemEntityList };
