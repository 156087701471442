import { gql } from '@apollo/client';

import { LoginReplyFragment } from '../fragments';

export const RefreshTokenMutation = gql`
    mutation RefreshToken($refreshToken: String!) {
        refreshToken(refreshToken: $refreshToken) {
            ...LoginReply
        }
    }
    ${LoginReplyFragment}
`;
