import { gql } from '@apollo/client';

export const GetRegionsQuery = gql`
    query GetRegions($args: RegionArgs) {
        getRegions(args: $args) {
            id
            name
        }
    }
`;
