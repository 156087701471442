import React from 'react';

import { ReactComponent as LogoIcon } from './source/avangard-logo.svg';
import { Root } from './shared/styled';

import type { IconProps } from './shared/types';

const Logo = (props: IconProps): React.ReactElement => {
    return (
        <Root {...props}>
            <LogoIcon />
        </Root>
    );
};

export { Logo };
