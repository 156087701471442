import React from 'react';
import { Formik, Form } from 'formik';
import { useMountedState } from 'react-use';

import { useEnqueueStacks } from '@modules/layout/hooks';
import { setServerErrors } from './helpers';

import type { ApolloError } from '@apollo/client';
import type { FormikValues, FormikConfig } from 'formik';

type ExtendedFormikProps<T> = FormikConfig<T> & {
    autoSubmitting?: boolean;
};

const ExtendedFormik = <T extends FormikValues>(
    props: ExtendedFormikProps<T>,
): React.ReactElement | null => {
    const { autoSubmitting = true, initialStatus, onSubmit, children, ...otherProps } = props;

    let innerRef: any = null;

    const isMounted = useMountedState();
    const { enqueueError } = useEnqueueStacks();

    if (typeof children !== 'function') {
        return null;
    }

    const handleSubmitExtended: ExtendedFormikProps<T>['onSubmit'] = async (
        values,
        formikHelpers,
    ) => {
        const extendsFormikHelpers = {
            ...formikHelpers,
            status: innerRef?.status,
        };

        const handleSetServerError = (error: string) => {
            formikHelpers.setStatus({ ...extendsFormikHelpers.status, serverError: error });

            enqueueError(error);
        };

        try {
            if (autoSubmitting) {
                formikHelpers.setSubmitting(true);
            }

            await onSubmit(values, extendsFormikHelpers);
        } catch (error) {
            let e: ApolloError;
            let dictionary: Record<string, any> = {};

            if (typeof error === 'object' && error.constructor === Object) {
                e = error.e;
                dictionary = error.dictionary;
            } else {
                e = error;
            }

            setServerErrors<T>({
                error: e,
                setFieldsFn: formikHelpers.setErrors,
                setGlobalFn: handleSetServerError,
                dictionary,
            });
        } finally {
            if (autoSubmitting && isMounted()) {
                formikHelpers.setSubmitting(false);
            }
        }
    };

    return (
        <Formik<T>
            innerRef={formikRef => (innerRef = formikRef)}
            initialStatus={{
                ...initialStatus,
                serverError: null,
            }}
            onSubmit={handleSubmitExtended}
            {...otherProps}
        >
            {formikProps => children(formikProps)}
        </Formik>
    );
};

export { ExtendedFormik, Form };
