import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { breakpoints } from '@avangard/ui/utils';

import { useTextDirection } from '@lib/i18n';
import { PlatformLoader } from '@modules/layout/moleculas';
import { PlatformCard, PlatformRegionalCard } from '@modules/auth/organisms';
import { Platform } from '@modules/types/graphql';

import type { PlatformAccessItemEntity } from '@modules/auth/entities';

type PlatformListProps = {
    loading: boolean;
    list?: PlatformAccessItemEntity[];
};

const Root = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1600px;
    min-height: calc(100vh - 120px);

    ${breakpoints.down(752)} {
        justify-content: flex-start;
    }

    ${breakpoints.down('xs')} {
        min-height: auto;
    }
`;

const List = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;

    & > * {
        margin: 10px 8px;
    }
`;

const Empty = styled.div`
    padding: 0 8px;
    color: #fff;
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
`;

const PlatformList = (props: PlatformListProps): React.ReactElement => {
    const { list = [], loading } = props;

    const { t } = useTranslation(['common']);

    const { isRtl } = useTextDirection();

    const resultList = isRtl() ? [...list].reverse() : list;

    const regionalCenter = resultList.find(
        platform => platform.platform === Platform.regionalCenter,
    );

    const onlyPlatformsList = resultList.filter(
        platform => platform.platform !== Platform.regionalCenter,
    );

    if (loading) {
        return (
            <Root>
                <PlatformLoader />
            </Root>
        );
    }

    if (list.length === 0) {
        return (
            <Root>
                <Empty>{t('common:stubs.empty_access')}</Empty>
            </Root>
        );
    }

    return (
        <Root>
            {regionalCenter ? (
                <List>
                    <PlatformRegionalCard />
                </List>
            ) : null}

            <List>
                {onlyPlatformsList.map((platform, index) => (
                    <PlatformCard
                        key={index}
                        platform={platform}
                        disabled={!platform?.isAvailable}
                    />
                ))}
            </List>
        </Root>
    );
};

export { PlatformList };
