import { gql } from '@apollo/client';

export const GetPlatformsAccessListQuery = gql`
    query GetPlatformsAccessList {
        getPlatformsAccessList {
            platform
            isAvailable
            lap {
                id
                from
                to
                name
                platform
                course {
                    id
                    name
                }
            }
        }
    }
`;
