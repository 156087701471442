import { useTranslation } from 'react-i18next';
import { useNavigate as baseUseNavigate } from '@reach/router';

import type { NavigateOptions } from '@reach/router';

const useNavigate = () => {
    const { i18n } = useTranslation();

    const baseNavigate = baseUseNavigate();

    const navigate = async (to: string, options: NavigateOptions<{}> = {}) => {
        if (to.startsWith('/' + i18n.language) || to.startsWith('#') || to.startsWith('../')) {
            return baseNavigate(to, options);
        }

        const toWithLang = '/' + i18n.language + to;

        return baseNavigate(toWithLang, options);
    };

    return navigate;
};

export { useNavigate };
