import React from 'react';

import { TextDirectionContext } from './text-direction-provider';

import type { TextDirectionProviderValue } from './types';

const useTextDirection = (): TextDirectionProviderValue => {
    const context = React.useContext(TextDirectionContext);

    if (context === undefined) {
        throw new Error('text direction context value are undefined');
    }

    return context;
};

export { useTextDirection };
