import { EntityList } from '@lib/entity';
import { RegionalCenterEntity } from './regional-center.entity';

import type { Entity } from './regional-center.entity';

class RegionalCenterEntityList extends EntityList<RegionalCenterEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(RegionalCenterEntity, items, count);
    }
}

export { RegionalCenterEntityList };
