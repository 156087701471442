import type { QueryHookOptions } from '@apollo/client';
import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { PlatformAccessItemEntityList } from '@modules/auth/entities';
import { GetPlatformsAccessListQuery } from '@modules/auth/graphql';

import type { GetPlatformsAccessListQueryType } from '@modules/types/graphql';

const usePlatformsAccessList = (options?: QueryHookOptions<GetPlatformsAccessListQueryType>) => {
    const queryResult = useQuery<GetPlatformsAccessListQueryType>(
        GetPlatformsAccessListQuery,
        options,
    );

    const { entities: platformsAccessList, count } = useEntity(() => {
        const entityList = new PlatformAccessItemEntityList(
            queryResult.data?.getPlatformsAccessList ?? null,
        );

        return { entities: entityList.getSorted(), count: entityList.count };
    }, [queryResult.data]);

    const result = {
        platformsAccessList,
        count,
        ...queryResult,
    };

    return result;
};

export { usePlatformsAccessList };
