import { useTranslation } from 'react-i18next';
import { Redirect as BaseRedirect } from '@reach/router';

import type { RedirectProps, RouteComponentProps } from '@reach/router';

const Redirect = <TState extends {}>(props: RouteComponentProps<RedirectProps<TState>>) => {
    const { to, ...otherProps } = props;

    const { i18n } = useTranslation();

    const toWithLang = '/' + i18n.language + to;

    return <BaseRedirect to={toWithLang} {...otherProps} />;
};

export { Redirect };
