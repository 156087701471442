import { gql } from '@apollo/client';

import { LoginReplyFragment } from '../fragments';

export const RegisterStudentMutation = gql`
    mutation RegisterStudent($args: RegisterStudentArgs!) {
        registerStudent(args: $args) {
            ...LoginReply
        }
    }
    ${LoginReplyFragment}
`;
