import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';

import { LapEntity } from './lap.entity';

import type { LapWithCertificateUrl } from '@modules/types/graphql';

export type LapWithCertificateRawEntity = Partial<LapWithCertificateUrl> | null;

@DecribeEntity('LapWithCertificateEntity')
class LapWithCertificateEntity extends BaseEntity {
    certificatesUrl: string;

    @Relation(() => LapEntity)
    lap: LapEntity;

    constructor(entity: LapWithCertificateRawEntity) {
        super(entity);

        this.certificatesUrl = entity?.certificatesUrl ?? '';
    }
}

export { LapWithCertificateEntity };
