import styled from 'styled-components';

const Root = styled.div`
    display: inline-flex;

    > svg {
        width: 100%;
        height: auto;
    }
`;

export { Root };
