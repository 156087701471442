import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Autocomplete } from '@avangard/ui/core';

import { useLazyRegions, useLazyLocations } from '@modules/shared/hooks';
import { useRegionalCenters } from '@modules/regional-center/hooks';

import type { RegionEntity, LocationEntity } from '@modules/shared/entities';
import type { RegionalCenterEntity } from '@modules/regional-center/entities';
import type { RegisterStudentFormValues } from '@modules/auth/requests';

const LocationFormGroup = () => {
    const { t } = useTranslation(['common']);

    const { values, errors, setFieldValue } = useFormikContext<RegisterStudentFormValues>();

    const { regionalCenters, loading: regionalCentersLoading } = useRegionalCenters();

    const [getRegions, { regions, count: regionsCount, loading: regionsLoading }] =
        useLazyRegions();

    const [getLocations, { locations, count: locationsCount, loading: locationsLoading }] =
        useLazyLocations();

    React.useEffect(() => {
        if (!values.regionalCenter) {
            return;
        }

        getRegions({
            variables: {
                args: {
                    filter: {
                        all: true,
                        ids: values.regionalCenter.regionIds(),
                    },
                },
            },
        });
    }, [values.regionalCenter]);

    React.useEffect(() => {
        if (!values.region) {
            return;
        }

        getLocations({ variables: { regionId: values.region.id } });
    }, [values.region]);

    const handleChangeAutocomplete =
        (field: string) => (_: React.ChangeEvent<unknown>, option: any | null) => {
            setFieldValue(field, option);

            if (field === 'regionalCenter') {
                setFieldValue('region', null);
                setFieldValue('location', null);
            }

            if (field === 'region') {
                setFieldValue('location', null);
            }
        };

    return (
        <>
            <Autocomplete<RegionalCenterEntity | null, false, false, false>
                fullWidth
                required
                id='regionalCenter'
                label={t('common:form.labels.regional_center') ?? ''}
                placeholder={t('common:form.placeholder.choose_regional_center') ?? ''}
                options={regionalCenters}
                value={values.regionalCenter}
                error={!!errors.regionalCenter}
                helperText={errors.regionalCenter}
                disabled={regionalCentersLoading}
                getOptionLabel={option => option?.name ?? '-'}
                onChange={handleChangeAutocomplete('regionalCenter')}
            />

            <Autocomplete<RegionEntity | null, false, false, false>
                fullWidth
                required
                id='region'
                label={t('common:form.labels.region') ?? ''}
                placeholder={t('common:form.placeholder.choose_region') ?? ''}
                options={regions}
                value={values.region}
                error={!!errors.region}
                helperText={errors.region}
                disabled={regionalCentersLoading || regionsCount === 0 || regionalCentersLoading}
                getOptionLabel={option => option?.name ?? '-'}
                onChange={handleChangeAutocomplete('region')}
            />

            <Autocomplete<LocationEntity | null, false, false, false>
                fullWidth
                required
                id='location'
                label={t('common:form.labels.location') ?? ''}
                placeholder={t('common:form.placeholder.choose_location') ?? ''}
                options={locations}
                value={values.location}
                error={!!errors.location}
                helperText={errors.location}
                disabled={regionsLoading || locationsCount === 0 || locationsLoading}
                getOptionLabel={option => option?.name ?? '-'}
                onChange={handleChangeAutocomplete('location')}
            />
        </>
    );
};

export { LocationFormGroup };
