import React from 'react';
import { Add } from '@material-ui/icons';
import { Button } from '@avangard/ui/core';

import type { ButtonProps } from '@avangard/ui/core';

const addIcon = <Add style={{ fontSize: 16 }} />;

const ButtonAdd = (props: ButtonProps) => <Button startIcon={addIcon} {...props}></Button>;

export { ButtonAdd };
