import { useQuery } from '@apollo/client';

import { GetUserInfoByTokenQuery } from '@modules/auth/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type {
    GetInfoByResetPasswordTokenQueryType,
    GetInfoByResetPasswordTokenQueryVariables,
} from '@modules/types/graphql';
import { useMemo } from 'react';

const useUserInfoByToken = (
    options?: QueryHookOptions<
        GetInfoByResetPasswordTokenQueryType,
        GetInfoByResetPasswordTokenQueryVariables
    >,
) => {
    const queryResult = useQuery<
        GetInfoByResetPasswordTokenQueryType,
        GetInfoByResetPasswordTokenQueryVariables
    >(GetUserInfoByTokenQuery, options);

    const userInfo = useMemo(() => {
        return queryResult.data?.getInfoByResetPasswordToken ?? { fullName: '' };
    }, [queryResult]);

    const result = {
        userInfo,
        ...queryResult,
    };

    return result;
};

export { useUserInfoByToken };
