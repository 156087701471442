import { IndexPage } from '@pages/index';
import { PlatformPage } from '@pages/auth/platform';
import { LoginPage } from '@pages/auth/login';
import { RegisterPage } from '@pages/auth/register';
import { ForgotPasswordPage } from '@pages/auth/forgot-password';
import { ResetPasswordPage } from '@pages/auth/reset-password';
import { AuthLayout, EmptyLayout } from '@modules/layout/templates';
import { UserRole } from '@modules/types/graphql';

import type { RouteComponentProps, RedirectProps } from '@reach/router';

export enum RouteMenuLocationEnum {
    sidebar = 'sidebar',
}

export type Route = {
    name: string;
    title: string;
    path: string;
    private: boolean;
    Component: React.FunctionComponent<any>;
    LayoutComponent: React.FunctionComponent<any>;
    menuTitle?: string;
    roles?: UserRole[];
    withBackIcon?: boolean;
    default?: boolean;
    menuLocations?: RouteMenuLocationEnum[];
    menuIcon?: React.FunctionComponent<any>;
    children?: React.ReactNode;
};

export type ConfigRoute = Record<string, Route>;

export type RedirectRoute = RouteComponentProps<RedirectProps<{}>> & {
    name: string;
};

const routes: ConfigRoute = {
    index: {
        name: 'index',
        title: 'Index',
        path: '/',
        private: true,
        Component: IndexPage,
        LayoutComponent: EmptyLayout,
    },

    platform: {
        name: 'auth.platform',
        title: 'common:menu.platform',
        path: '/auth/platform',
        private: true,
        Component: PlatformPage,
        LayoutComponent: EmptyLayout,
    },

    login: {
        name: 'auth.login',
        title: 'common:menu.auth',
        path: '/auth/login',
        private: false,
        Component: LoginPage,
        LayoutComponent: AuthLayout,
    },
    register: {
        name: 'auth.register',
        title: 'common:menu.register',
        path: '/auth/register',
        private: false,
        Component: RegisterPage,
        LayoutComponent: AuthLayout,
    },

    forgotPassword: {
        name: 'auth.forgotPassword',
        title: 'common:menu.forgot_password',
        path: '/auth/forgot-password',
        private: false,
        Component: ForgotPasswordPage,
        LayoutComponent: AuthLayout,
    },

    resetPassword: {
        name: 'auth.resetPassword',
        title: 'common:menu.reset_password',
        path: '/reset-password',
        private: false,
        Component: ResetPasswordPage,
        LayoutComponent: AuthLayout,
    },

    error: {
        name: 'error',
        title: 'common:menu.service_error',
        path: '/error',
        private: false,
        Component: IndexPage, // TODO: Create Error page
        LayoutComponent: EmptyLayout,
    },
};

const redirects: RedirectRoute[] = [
    {
        name: 'default',
        from: '/:lang',
        to: routes.login.path,
        default: true,
    },
];

export { routes, redirects };
