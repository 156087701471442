import { i18n } from '@lib/i18n';

import type { RegionEntity, LocationEntity } from '@modules/shared/entities';
import type { RegionalCenterEntity } from '@modules/regional-center/entities';
import type { RegisterStudentArgs } from '@modules/types/graphql';

export type RegisterStudentFormValues = {
    fullName: string;
    phone: string;
    email: string;
    password: string;
    confirmPassword: string;
    region: RegionEntity | null;
    location: LocationEntity | null;
    regionalCenter: RegionalCenterEntity | null;
};

class RegisterStudentRequest {
    fullName: RegisterStudentArgs['fullName'];
    phone: RegisterStudentArgs['phone'];
    email: RegisterStudentArgs['email'];
    password: RegisterStudentArgs['password'];
    regionId: RegisterStudentArgs['regionId'];
    locationId: RegisterStudentArgs['locationId'];
    regionalCenterId: RegisterStudentArgs['regionalCenterId'];

    constructor(values: RegisterStudentFormValues) {
        if (!values.region || !values.location || !values.regionalCenter) {
            throw new Error(i18n.t('errors:validations.invalid_form_data') ?? '');
        }

        this.fullName = values.fullName;
        this.phone = values.phone;
        this.email = values.email;
        this.password = values.password;
        this.regionId = values.region.id;
        this.locationId = values.location.id;
        this.regionalCenterId = values.regionalCenter.id;
    }
}

export { RegisterStudentRequest };
