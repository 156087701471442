import React from 'react';
import styled, { css } from 'styled-components';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { gray } from '@avangard/ui/colors';
import { breakpoints } from '@avangard/ui/utils';

import { useEnqueueStacks } from '@modules/layout/hooks';
import { useCurrentUser } from '@modules/auth/hooks';
import { PlatformLoader } from '@modules/layout/moleculas';
import { GetPlatformsAccessListQuery } from '@modules/auth/graphql';
import { RegionalCenterEntity } from '@modules/regional-center/entities';
import { RegionalCenterRegionsInfo } from '@modules/regional-center/atoms';
import { RegionalCenter } from '@modules/regional-center/moleculas';
import { SetCurrentRegionalCenterMutation } from '@modules/regional-center/graphql';

import type {
    SetCurrentRegionalCenterMutationVariables,
    SetCurrentRegionalCenterMutationType,
} from '@modules/types/graphql';

type ChooseRegionalCenterProps = {
    loading: boolean;
    regionalCenters: RegionalCenterEntity[];
    currentRegionalCenter: RegionalCenterEntity;
};

const Root = styled.div`
    display: inline-block;
    position: relative;
    min-width: 200px;
    max-width: 250px;
`;

const DropdownButton = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-size: 1.4em;
    color: #ffffff;
    cursor: pointer;

    &:hover {
        color: #ccc;
    }

    ${breakpoints.down('xs')} {
        justify-content: center;
    }
`;

const Icon = styled.div`
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: 4px;

    > svg {
        font-size: 1.8rem;
    }
`;

const DropdownMenu = styled.div<{ open: boolean }>`
    display: ${props => (props.open ? 'block' : 'none')};
    position: absolute;
    top: calc(60% + 4px);
    left: 0;
    width: 100%;
    max-height: 200px;
    padding: 6px 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow-y: auto;
    z-index: 100;
`;

const DropdownMenuItem = styled.div<{ current?: boolean }>`
    padding: 6px 8px;
    font-size: 1.4rem;
    cursor: pointer;
    background-color: transparent;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: 0.2s;

    &:hover {
        background-color: ${gray[60]};
    }

    ${p =>
        p.current &&
        css`
            cursor: none;
            pointer-events: none;
            background-color: ${gray[40]};
        `}
`;

const ChooseRegionalCenter = (props: ChooseRegionalCenterProps) => {
    const { loading, currentRegionalCenter, regionalCenters } = props;

    const { t } = useTranslation(['common', 'regional_center']);

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { currentUser } = useCurrentUser();

    const [open, setOpen] = React.useState(false);

    const handleClose = () => setOpen(false);
    const handleToggleOpen = () => setOpen(state => !state);

    const [internalRegionalCenterId, setInternalRegionalCenterId] = React.useState('');

    React.useEffect(() => {
        if (internalRegionalCenterId !== currentRegionalCenter.id) {
            setInternalRegionalCenterId(currentRegionalCenter.id);
        }
    }, [currentRegionalCenter.id]);

    const [setRegionalCenter] = useMutation<
        SetCurrentRegionalCenterMutationType,
        SetCurrentRegionalCenterMutationVariables
    >(SetCurrentRegionalCenterMutation);

    const handleSelect = (id: string) => async () => {
        setInternalRegionalCenterId(id);

        handleClose();

        try {
            await setRegionalCenter({
                variables: { id },
                refetchQueries: [{ query: GetPlatformsAccessListQuery }],
            });

            enqueueSuccess(t('regional_center:notifiers.set_regional_center.success'));
        } catch (e) {
            enqueueError(t('regional_center:notifiers.set_regional_center.error'));
        }
    };

    if (loading) {
        return (
            <Root>
                <PlatformLoader />
            </Root>
        );
    }

    if (currentUser.isSuperAdmin()) {
        const internalCurrentRegionalCenter = regionalCenters.find(
            regionalCenter => regionalCenter.id === internalRegionalCenterId,
        );

        if (!internalCurrentRegionalCenter) {
            return null;
        }

        return (
            <ClickAwayListener onClickAway={handleClose}>
                <Root>
                    <DropdownButton onClick={handleToggleOpen}>
                        <p>{internalCurrentRegionalCenter.name}</p>
                        <Icon>{open ? <ExpandLess /> : <ExpandMore />}</Icon>
                    </DropdownButton>

                    <DropdownMenu open={open}>
                        {regionalCenters.map(item => (
                            <DropdownMenuItem
                                key={item.id}
                                current={item.id === internalRegionalCenterId}
                                onClick={handleSelect(item.id)}
                            >
                                {item.name}
                            </DropdownMenuItem>
                        ))}
                    </DropdownMenu>

                    <RegionalCenterRegionsInfo regionalCenter={internalCurrentRegionalCenter} />
                </Root>
            </ClickAwayListener>
        );
    }

    return <RegionalCenter currentRegionalCenter={currentRegionalCenter} />;
};

export { ChooseRegionalCenter };
