import styled from 'styled-components';
import ReactCountryFlag from 'react-country-flag';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@material-ui/core';
import { TextField } from '@avangard/ui/core';
import { black } from '@avangard/ui/colors';

import { useNavigate } from '@lib/routing';

import type { Theme } from '@material-ui/core';

type ColorKeys = 'white' | 'black';

type ChangeLanguageProps = {
    color: ColorKeys;
};

const COLOR_RGBA_MAP = {
    white: '#fff',
    black: black[60],
};

const useSelectStyles = makeStyles<Theme, ChangeLanguageProps>({
    select: p => ({
        color: COLOR_RGBA_MAP[p.color],
    }),
    icon: p => ({
        color: COLOR_RGBA_MAP[p.color],
    }),
});

const useInputStyles = makeStyles<Theme, ChangeLanguageProps>({
    notchedOutline: p => ({
        borderColor: COLOR_RGBA_MAP[p.color],
    }),
});

const Language = styled.span`
    display: flex;
    flex-wrap: wrap;
    margin-inline-end: 8px;
    line-height: 1;
`;

const LANGUAGE_FLAGS_MAP = {
    ru: <ReactCountryFlag countryCode='RU' style={{ fontSize: 16 }} />,
    en: <ReactCountryFlag countryCode='US' style={{ fontSize: 16 }} />,
    ar: <ReactCountryFlag countryCode='AE' style={{ fontSize: 16 }} />,
};

const ChangeLanguage = (props: ChangeLanguageProps): React.ReactElement => {
    const { color } = props;

    const { i18n } = useTranslation();

    const navigate = useNavigate();

    const selectClasses = useSelectStyles({ color });
    const inputClasses = useInputStyles({ color });

    const handleChangeLanguage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const language = event.target.value;

        await i18n.changeLanguage(language);

        navigate(window.location.pathname);
    };

    const renderLanguage = (language: string) => {
        if (language === 'ar') {
            return 'uae';
        }

        return language;
    };

    return (
        <TextField
            select
            name='language'
            value={i18n.language}
            onChange={handleChangeLanguage}
            SelectProps={{ classes: selectClasses }}
            InputProps={{ classes: inputClasses }}
        >
            {Object.keys(LANGUAGE_FLAGS_MAP).map(language => (
                <MenuItem key={language} value={language}>
                    <Language>{LANGUAGE_FLAGS_MAP[language]}</Language>

                    {renderLanguage(language).toUpperCase()}
                </MenuItem>
            ))}
        </TextField>
    );
};

export { ChangeLanguage };
