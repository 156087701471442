import React from 'react';
import styled, { css } from 'styled-components';
import { black, blue } from '@avangard/ui/colors';

type HintProps = {
    children: React.ReactNode;
    dense?: boolean;
};

const Root = styled.div<Pick<HintProps, 'dense'>>`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 8px 16px;
    color: ${black[80]};
    font-size: 1.4rem;
    line-height: 22px;
    border: 1px solid ${blue[40]};
    border-radius: 4px;
    background-color: ${blue[10]};

    ${p =>
        !!p.dense &&
        css`
            padding: 4px 16px;
        `}
`;

const Hint = (props: HintProps): React.ReactElement => {
    const { dense, children } = props;

    return <Root dense={dense}>{children}</Root>;
};

export { Hint };
