import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { Student } from '@modules/types/graphql';

export type Entity = Partial<Omit<Student, 'school' | 'attachedUser'>> | null;

@DecribeEntity('StudentEntity')
class StudentEntity extends BaseEntity {
    id: string;
    fullName: string;
    olCourseCertificateUrl: string;

    constructor(student: Entity) {
        super(student);

        this.id = student?.id ?? '';
        this.fullName = student?.fullName ?? '';
        this.olCourseCertificateUrl = student?.olCourseCertificateUrl ?? '';
    }
}

export { StudentEntity };
