import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { LapWithCertificateEntityList } from '@modules/lap/entities';
import { GetLapsWithCertificatesQuery } from '@modules/lap/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetLapsWithCertificatesQueryType } from '@modules/types/graphql';

const useLapsWithCertificates = (options?: QueryHookOptions<GetLapsWithCertificatesQueryType>) => {
    const queryResult = useQuery<GetLapsWithCertificatesQueryType>(
        GetLapsWithCertificatesQuery,
        options,
    );

    const { entities: lapsWithCertificates } = useEntity(
        () => new LapWithCertificateEntityList(queryResult.data?.getLapsWithCertificates ?? null),
        [queryResult.data],
    );

    const preparedLapsWithCertificates = lapsWithCertificates.filter(entity =>
        Boolean(entity.certificatesUrl),
    );

    const result = {
        lapsWithCertificates: preparedLapsWithCertificates,
        lapsWithCertificatesCount: preparedLapsWithCertificates.length,
        ...queryResult,
    };

    return result;
};

export { useLapsWithCertificates };
