import { useLazyQuery } from '@apollo/client';
import { useEntity } from '@lib/entity';

import { RegionEntityList } from '@modules/shared/entities';
import { GetRegionsQuery } from '@modules/shared/graphql';

import type { QueryLazyOptions, LazyQueryResult, QueryHookOptions } from '@apollo/client';
import type { RegionEntity } from '@modules/shared/entities';
import type { GetRegionsQueryType, GetRegionsQueryVariables } from '@modules/types/graphql';

type Tuple = [
    (options?: QueryLazyOptions<GetRegionsQueryVariables>) => void,
    LazyQueryResult<GetRegionsQueryType, GetRegionsQueryVariables> & {
        regions: RegionEntity[];
        count: number;
    },
];

const useLazyRegions = (
    options?: QueryHookOptions<GetRegionsQueryType, GetRegionsQueryVariables>,
): Tuple => {
    const [getRegions, queryResult] = useLazyQuery<GetRegionsQueryType, GetRegionsQueryVariables>(
        GetRegionsQuery,
        options,
    );

    const { entities: regions, count } = useEntity(
        () => new RegionEntityList(queryResult.data?.getRegions ?? null),
        [queryResult.data],
    );

    return [getRegions, { regions, count, ...queryResult }];
};

export { useLazyRegions };
