import styled from 'styled-components';
import { ExitToApp } from '@material-ui/icons';
import { isRtl } from '@avangard/ui/utils';

const Root = styled.span`
    display: inline-flex;

    ${isRtl`
      transform: rotate(180deg);
    `}
`;

const logoutIcon = <ExitToApp fontSize='large' />;

const LogoutIcon = (): React.ReactElement => {
    return <Root>{logoutIcon}</Root>;
};

export { LogoutIcon };
