import { LapProgramType } from '@modules/types/graphql';

const programTypeTranslates = {
    [LapProgramType.savingIra]: 'common:blocks.program_types.saving_ira',
    [LapProgramType.heroesWay]: 'common:blocks.program_types.heroes_way',
    [LapProgramType.binn]: 'common:blocks.program_types.binn',
    [LapProgramType.zone_55]: 'common:blocks.program_types.zone_55',
    [LapProgramType.swatCup]: 'common:blocks.program_types.swat_cup',
};

const programTypes = Object.entries(LapProgramType).map(([_, value]) => ({
    key: value,
    value: programTypeTranslates[value] ?? value,
}));

export { programTypeTranslates, programTypes };
