import React from 'react';

import { initI18n } from './init';

const useInitI18n = () => {
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        const run = async () => {
            setLoading(true);

            await initI18n();

            setLoading(false);
        };

        run();
    }, []);

    return { loading };
};

export { useInitI18n };
