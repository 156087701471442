import { gql } from '@apollo/client';

import { LoginReplyFragment } from '../fragments';

export const LoginMutation = gql`
    mutation Login($login: String!, $password: String!) {
        login(login: $login, password: $password) {
            ...LoginReply
        }
    }
    ${LoginReplyFragment}
`;
