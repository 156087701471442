import qs from 'query-string';

import type { Route } from '@config/routes';

type CreateRoutePayload = Record<string, any> & {
    query?: Record<string, any>;
};

const ignoredPayload = ['query'];

export const createRouteUrl = (route: Route | string, payload?: CreateRoutePayload) => {
    let url = '';

    if (typeof route === 'string') {
        url = route;
    } else {
        url = route.path;
    }

    if (!payload) {
        return url;
    }

    const payloadEntries = Object.entries(payload);

    payloadEntries.forEach(([key, item]) => {
        if (ignoredPayload.includes(key)) {
            return;
        }

        const regExp = new RegExp(`:${key}`, 'g');

        url = url.replace(regExp, item);
    });

    if (payload.query) {
        const queryString = qs.stringify(payload.query, { skipEmptyString: true });

        url = url + '?' + queryString;
    }

    return url;
};
