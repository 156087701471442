import type { ResetPasswordMutationVariables } from '@modules/types/graphql';

export type ResetPasswordFormValues = {
    token: string;
    newPassword: string;
};

class ResetPasswordRequest {
    token: ResetPasswordMutationVariables['token'];
    newPassword: ResetPasswordMutationVariables['newPassword'];

    constructor(values: ResetPasswordFormValues) {
        this.token = values.token;
        this.newPassword = values.newPassword;
    }
}

export { ResetPasswordRequest };
