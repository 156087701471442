import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { RegionalCenterEntityList } from '@modules/regional-center/entities';
import { GetRegionalCenterCollectionQuery } from '@modules/regional-center/graphql';

import type { GetRegionalCenterCollectionQueryType } from '@modules/types/graphql';

const useRegionalCenters = () => {
    const queryResult = useQuery<GetRegionalCenterCollectionQueryType>(
        GetRegionalCenterCollectionQuery,
    );

    const { entities: regionalCenters } = useEntity(
        () =>
            new RegionalCenterEntityList(
                queryResult.data?.getRegionalCenterCollection?.items ?? null,
            ),
        [queryResult.data],
    );

    const result = {
        regionalCenters,
        ...queryResult,
    };

    return result;
};

export { useRegionalCenters };
