import { Redirect } from '@lib/routing';

import type { RedirectRoute } from '@config/routes';

const renderRedirects = (redirects: RedirectRoute[]) => {
    const rendered = redirects.map(redirect => (
        <Redirect key={redirect.name} noThrow {...redirect} />
    ));

    return rendered;
};

export { renderRedirects };
