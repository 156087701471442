import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Tab } from '@avangard/ui/core';
import { red } from '@avangard/ui/colors';
import { concatenateClasses } from '@avangard/ui/utils';

import type { TabProps } from '@avangard/ui/core';

const useTabStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'transparent',

        [theme.breakpoints.down('md')]: {
            padding: 0,

            '&:not(:last-child)': {
                marginInlineEnd: 24,
            },
        },
    },

    textColorInherit: {
        '&$selected': {
            color: red[100],
            backgroundColor: red[10],

            [theme.breakpoints.down('md')]: {
                color: red[100],
                backgroundColor: 'transparent',
            },
        },
    },

    wrapper: {
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
    },

    selected: {},
}));

const TabEdit = (props: TabProps): React.ReactElement => {
    const { classes, ...otherProps } = props;

    const tabClasses = concatenateClasses(useTabStyles(), classes);

    return <Tab classes={tabClasses} {...otherProps} />;
};

export { TabEdit };
