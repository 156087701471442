import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { UserEntity } from '@modules/auth/entities';
import { GetCurrentUserQuery } from '@modules/auth/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetCurrentUserQueryType } from '@modules/types/graphql';

const useCurrentUser = (options?: QueryHookOptions<GetCurrentUserQueryType>) => {
    const queryResult = useQuery<GetCurrentUserQueryType>(GetCurrentUserQuery, options);

    const currentUser = useEntity(
        () => new UserEntity(queryResult.data?.getCurrentUser ?? null),
        [queryResult.data],
    );

    const result = {
        currentUser,
        ...queryResult,
    };

    return result;
};

export { useCurrentUser };
