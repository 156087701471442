import { Platform, PlatformFilter } from '@modules/types/graphql';

const platformTranslates = {
    [Platform.center]: 'common:blocks.platform.center',
    [Platform.online]: 'common:blocks.platform.online',
};

const platforms = Object.entries(platformTranslates).map(([key, value]) => ({
    key,
    value,
}));

const platformFilterTranslates = {
    [PlatformFilter.center]: 'common:blocks.platform.center',
    [PlatformFilter.online]: 'common:blocks.platform.online',
    [PlatformFilter.noPlatform]: 'common:blocks.platform.no_platform',
};

const platformFilterOptions = Object.entries(platformFilterTranslates).map(([key, value]) => ({
    key,
    value,
}));

export { platformTranslates, platforms, platformFilterTranslates, platformFilterOptions };
