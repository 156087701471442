import React from 'react';
import ym from 'react-yandex-metrika';
import { usePrevious } from 'react-use';
import { useLocation } from '@reach/router';

import { useCurrentUser } from '@modules/auth/hooks';

const YMWidget = (): null => {
    const location = useLocation();

    const { currentUser } = useCurrentUser({ fetchPolicy: 'cache-only' });
    const previousCurrentUser = usePrevious(currentUser);

    React.useEffect(() => {
        if (previousCurrentUser?.id === currentUser.id) {
            return;
        }

        if (currentUser.authenticated()) {
            ym('userParams', {
                fullName: currentUser.fullName,
                UserID: currentUser.id,
            });
        }
    }, [currentUser, location.pathname]);

    return null;
};

export { YMWidget };
