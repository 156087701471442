import { Trans, useTranslation } from 'react-i18next';
import { withStyles, FormControlLabel, Checkbox } from '@material-ui/core';
import styled, { css } from 'styled-components';
import { ChangeEvent } from 'react';

type AgreeCheckboxProps = {
    name?: string;
    onChange?: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    hasError?: boolean;
};

const StyledFormControlLabel = withStyles({
    root: {
        marginBottom: 24,
    },
})(FormControlLabel);

const AgreeText = styled.span<{ error: boolean }>`
    display: inline-block;
    font-size: 1.2rem;

    a {
        color: inherit;
        text-decoration: underline;
    }

    ${p =>
        p.error &&
        css`
            color: red;
            animation: bounce 0.5s ease 1;

            @keyframes bounce {
                30% {
                    transform: translateY(0%);
                }
                50% {
                    transform: translateY(-15%);
                }
                70% {
                    transform: translateY(0%);
                }
                85% {
                    transform: translateY(-7%);
                }
                90% {
                    transform: translateY(0%);
                }
                95% {
                    transform: translateY(-3%);
                }
                100% {
                    transform: translateY(0);
                }
            }
        `}
`;

const AgreeCheckbox = (props: AgreeCheckboxProps) => {
    const { t } = useTranslation(['common']);

    return (
        <StyledFormControlLabel
            control={<Checkbox name={props.name ?? 'agree'} onChange={props.onChange} />}
            label={
                <AgreeText error={props.hasError ?? false}>
                    <Trans
                        t={t}
                        i18nKey='common:form.labels.agree'
                        components={{
                            term_link: (
                                <a
                                    href='https://storage.yandexcloud.net/avangard-static/obrabotka-personalnih-dannih.pdf'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    .
                                </a>
                            ),
                        }}
                    />
                </AgreeText>
            }
        />
    );
};

export { AgreeCheckbox };
