import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { gray, white } from '@avangard/ui/colors';
import { breakpoints, isRtl } from '@avangard/ui/utils';

import { TokenSession } from '@lib/token';
import { CENTER_DOMAIN, ONLINE_DOMAIN, REGIONAL_CENTERS_DOMAIN } from '@config/environment';
import { PlatformAccessItemEntity } from '@modules/auth/entities';
import {
    centerLogo,
    onlineLogo,
    centerBackground,
    onlineBackground,
    regionalCentersBackground,
    regionalCentersLogo,
} from '@modules/auth/helpers/assets/images';
import { SetCurrentLapMutation } from '@modules/lap/graphql';

import type {
    Platform,
    SetCurrentLapMutationType,
    SetCurrentLapMutationVariables,
} from '@modules/types/graphql';

type PlatformCardProps = {
    platform: PlatformAccessItemEntity;
    placeholder?: boolean;
    disabled?: boolean;
};

const Root = styled.div<{ background: string; disabled?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 450px;
    min-height: 250px;
    width: 100%;
    padding: 20px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    transform: scale(1);

    &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: ${p => `url(${p.background})`};

        ${isRtl`
            left: initial;
            right: 0;
        `}
    }

    &:hover {
        transform: scale(1.05);
        filter: brightness(130%);
    }

    ${p =>
        !!p.disabled &&
        `
        opacity: 0.8;
        cursor: default;
        pointer-events: none;

        &:before {
            filter: grayscale(100%) brightness(50%);
        }
  `}

    ${breakpoints.down(1600)} {
        max-width: 360px;
        padding: 16px;
    }

    ${breakpoints.down(600)} {
        max-width: 320px;
        padding: 12px;
    }
`;

const Logo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 144px;
    flex: 0 0 auto;

    img {
        object-fit: contain;
        height: 100%;
    }

    ${breakpoints.down(1600)} {
        width: 120px;
    }

    ${breakpoints.down(600)} {
        width: 100px;
    }
`;

const Tag = styled.span`
    position: absolute;
    right: 20px;
    top: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 8px 18px;
    border: 1px solid ${gray[70]};
    color: ${gray[70]};
    font-size: 12px;
    font-weight: 700;

    ${breakpoints.down(600)} {
        right: 10px;
        top: 8px;
        padding: 4px 10px;
        font-size: 9px;

        ${isRtl`
            right: initial;
            left: 10px;
        `}
    }

    ${isRtl`
        right: initial;
        left: 20px;
    `}
`;

const Main = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${white[100]};
    width: 100%;
    margin-top: 16px;

    > *:not(last-child) {
        margin-bottom: 6px;
    }
`;

const Text = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-align: center;
`;

const platforms = {
    center: {
        background: centerBackground,
        logo: centerLogo,
        domain: CENTER_DOMAIN,
    },
    online: {
        background: onlineBackground,
        logo: onlineLogo,
        domain: ONLINE_DOMAIN,
    },
    regional_center: {
        background: regionalCentersBackground,
        logo: regionalCentersLogo,
        domain: REGIONAL_CENTERS_DOMAIN,
    },
};

const PlatformCard = (props: PlatformCardProps): React.ReactElement | null => {
    const { platform, placeholder, disabled } = props;

    const { t, i18n } = useTranslation(['common']);

    const [setCurrentLap] =
        useMutation<SetCurrentLapMutationType, SetCurrentLapMutationVariables>(
            SetCurrentLapMutation,
        );

    const handleChoosePlatform = (platform: PlatformAccessItemEntity) => async () => {
        if (disabled) {
            return;
        }

        const lapId = platform.lap?.id ?? '';
        const platformType = platform.platform as Platform;
        const redirectTo = content.domain + '/' + i18n.language;

        const accessToken = TokenSession.getCurrentSession().getAccessToken().getToken();
        const refreshToken = TokenSession.getCurrentSession().getRefreshToken().getToken();

        if (!accessToken || !refreshToken || !redirectTo) {
            return;
        }

        try {
            if (lapId) {
                await setCurrentLap({ variables: { id: lapId, platform: platformType } });
            }
        } catch (e) {
            throw e;
        } finally {
            TokenSession.setCurrentSession({ accessToken, refreshToken });
            window.location.href = redirectTo;
        }
    };

    const content = platforms[platform.platform];
    const isCenter = platform.platform === 'center';
    const isRegionalCenters = platform.platform === 'regional_center';
    const isPlaceholder = placeholder;

    return (
        <Root
            disabled={disabled}
            background={content.background}
            onClick={handleChoosePlatform(platform)}
        >
            <Logo>
                <img src={content.logo} alt='logo' />
            </Logo>

            <Tag>
                {disabled
                    ? t('common:blocks.status.have_not_access')
                    : t('common:blocks.status.have_access')}
            </Tag>

            <Main>
                {!isPlaceholder ? (
                    <Text>
                        {isCenter
                            ? t('common:blocks.course.center')
                            : isRegionalCenters
                            ? t('common:blocks.course.regionalCenters')
                            : platform?.lap?.course?.name ?? t('common:blocks.course.online')}
                    </Text>
                ) : null}

                <Text>{platform?.lap?.name}</Text>
            </Main>
        </Root>
    );
};

export { PlatformCard };
