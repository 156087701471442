import { useLazyQuery } from '@apollo/client';
import { useEntity } from '@lib/entity';

import { LocationEntityList } from '@modules/shared/entities';
import { GetLocationsQuery } from '@modules/shared/graphql';

import type { QueryLazyOptions, LazyQueryResult, QueryHookOptions } from '@apollo/client';
import type { LocationEntity } from '@modules/shared/entities';
import type { GetLocationsQueryType, GetLocationsQueryVariables } from '@modules/types/graphql';

type Tuple = [
    (options?: QueryLazyOptions<GetLocationsQueryVariables>) => void,
    LazyQueryResult<GetLocationsQueryType, GetLocationsQueryVariables> & {
        locations: LocationEntity[];
        count: number;
    },
];

const useLazyLocations = (
    options?: QueryHookOptions<GetLocationsQueryType, GetLocationsQueryVariables>,
): Tuple => {
    const [getLocations, queryResult] = useLazyQuery<
        GetLocationsQueryType,
        GetLocationsQueryVariables
    >(GetLocationsQuery, options);

    const { entities: locations, count } = useEntity(
        () => new LocationEntityList(queryResult.data?.getLocations ?? null),
        [queryResult.data],
    );

    return [getLocations, { locations, count, ...queryResult }];
};

export { useLazyLocations };
