import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { RegionalCenterEntity } from '@modules/regional-center/entities';
import { GetCurrentRegionalCenterQuery } from '@modules/regional-center/graphql';

import type { GetCurrentRegionalCenterQueryType } from '@modules/types/graphql';

const useCurrentRegionalCenter = () => {
    const queryResult = useQuery<GetCurrentRegionalCenterQueryType>(GetCurrentRegionalCenterQuery);

    const currentRegionalCenter = useEntity(
        () => new RegionalCenterEntity(queryResult.data?.getCurrentRegionalCenter ?? null),
        [queryResult.data],
    );

    const result = {
        currentRegionalCenter,
        ...queryResult,
    };

    return result;
};

export { useCurrentRegionalCenter };
