import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useMutation } from '@apollo/client';
import { black } from '@avangard/ui/colors';
import { TextField, Button } from '@avangard/ui/core';

import { routes } from '@config/routes';
import { useNavigate } from '@lib/routing';
import { TokenSession } from '@lib/token';
import { FormStyled } from '@modules/layout/styled';
import { useLocationQuery } from '@modules/layout/hooks';
import { Loader } from '@modules/layout/moleculas';
import { logout } from '@modules/auth/helpers';
import { useUserInfoByToken } from '@modules/auth/hooks';
import { ResetPasswordRequest } from '@modules/auth/requests';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { ResetPasswordMutation } from '@modules/auth/graphql';

import type {
    ResetPasswordMutationType,
    ResetPasswordMutationVariables,
} from '@modules/types/graphql';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    width: 100%;
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    > * {
        width: 100%;
    }
`;

const Title = styled.h3`
    color: ${black[80]};
    font-size: 2.3rem;
    font-weight: 500;
`;

const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 24px;
`;

const StyledForm = styled(Form)`
    width: 100%;

    > *:not(:last-child) {
        margin-bottom: 24px;
    }
`;

const ResetPasswordForm = (): React.ReactElement | null => {
    const { t } = useTranslation(['common', 'errors', 'auth']);

    const navigate = useNavigate();
    const apolloClient = useApolloClient();
    const [query] = useLocationQuery();

    const token = query?.token as string;

    const {
        userInfo,
        loading: userInfoLoading,
        error: userInfoError,
    } = useUserInfoByToken({
        skip: !token,
        variables: {
            token,
        },
        onCompleted: async () => {
            await logout(apolloClient);
        },
    });

    const [resetPassword] =
        useMutation<ResetPasswordMutationType, ResetPasswordMutationVariables>(
            ResetPasswordMutation,
        );

    const handleClickRedirectToAuth = (): void => {
        navigate(routes.index.path);
    };

    if (userInfoLoading) {
        return (
            <Root>
                <Loader />
            </Root>
        );
    }

    if (userInfoError) {
        return (
            <Root>
                <Header>
                    <Title>{t('auth:blocks.titles.reset_password_expire')}</Title>
                    <Main>
                        <Button onClick={handleClickRedirectToAuth}>
                            {t('common:blocks.common.return_to_auth')}
                        </Button>
                    </Main>
                </Header>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                <Title>
                    {t('auth:blocks.titles.reset_password_for', { userName: userInfo.fullName })}
                </Title>
            </Header>

            <Main>
                <ExtendedFormik
                    validationSchema={yup.object({
                        newPassword: yup
                            .string()
                            .required(t('errors:password.required') ?? '')
                            .min(8, t('errors:password.min') ?? ''),
                        confirmNewPassword: yup
                            .string()
                            .required(t('errors:validations.required') ?? '')
                            .oneOf(
                                [yup.ref('newPassword'), null],
                                t('errors:password.new_must_equal') ?? '',
                            ),
                    })}
                    initialValues={{
                        newPassword: '',
                        confirmNewPassword: '',
                    }}
                    onSubmit={async values => {
                        try {
                            const variables = new ResetPasswordRequest({ token, ...values });

                            const { data: resetPasswordData } = await resetPassword({
                                variables,
                            });

                            const result = resetPasswordData?.resetPassword;

                            if (!result?.accessToken) {
                                throw new Error(t('errors:password.general_error') ?? '');
                            }

                            TokenSession.setCurrentSession(result);

                            await navigate(routes.index.path);
                        } catch (e) {
                            throw e;
                        }
                    }}
                >
                    {formikProps => {
                        const { values, errors, handleChange, handleBlur, isSubmitting } =
                            formikProps;

                        return (
                            <StyledForm>
                                <TextField
                                    fullWidth
                                    type='password'
                                    id='newPassword'
                                    label={t('common:form.labels.new_password')}
                                    placeholder={t('common:form.placeholder.password') ?? ''}
                                    value={values.newPassword}
                                    error={!!errors.newPassword}
                                    helperText={errors.newPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <TextField
                                    fullWidth
                                    type='password'
                                    id='confirmNewPassword'
                                    label={t('common:form.labels.repeat_new_password')}
                                    placeholder={t('common:form.placeholder.password') ?? ''}
                                    value={values.confirmNewPassword}
                                    error={!!errors.confirmNewPassword}
                                    helperText={errors.confirmNewPassword}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                <FormStyled.FormRowSubmit>
                                    <Button fullWidth type='submit' loading={isSubmitting}>
                                        {t('common:actions.save_and_enter')}
                                    </Button>
                                </FormStyled.FormRowSubmit>
                            </StyledForm>
                        );
                    }}
                </ExtendedFormik>
            </Main>
        </Root>
    );
};

export { ResetPasswordForm };
