import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useMutation } from '@apollo/client';
import { Button } from '@avangard/ui/core';
import { white } from '@avangard/ui/colors';
import { breakpoints, isRtl } from '@avangard/ui/utils';

import { TokenSession } from '@lib/token';
import { useNavigate } from '@lib/routing';
import { routes } from '@config/routes';
import { LogoutIcon } from '@modules/layout/atoms';
import { DownloadCertificate, ChangeLanguage } from '@modules/layout/moleculas';
import { usePlatformsAccessList } from '@modules/auth/hooks';
import { PlatformList } from '@modules/auth/organisms';
import { RevokeTokenMutation } from '@modules/auth/graphql';
import { ChooseRegionalCenter } from '@modules/regional-center/moleculas';
import { useCurrentRegionalCenter, useRegionalCenters } from '@modules/regional-center/hooks';

import type { RevokeTokenMutationType, RevokeTokenMutationVariables } from '@modules/types/graphql';

const Root = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
        display: none;
    }
`;

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    min-height: 100%;
    position: relative;
    background-image: url('/images/main-background.jpg');
    background-size: cover;

    &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.4);

        ${isRtl`
            left: initial;
            right: 0;
        `}
    }

    ${breakpoints.down('xs')} {
        min-height: calc(100vh);
    }

    & > * {
        z-index: 1;
    }
`;

const HeaderBlock = styled.div<{ align: 'left' | 'center' | 'right' }>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    ${p =>
        (p.align === 'left' &&
            css`
                justify-content: flex-start;

                ${breakpoints.down('xs')} {
                    order: 1;
                }
            `) ||
        (p.align === 'center' &&
            css`
                justify-content: center;
            `) ||
        (p.align === 'right' &&
            css`
                justify-content: flex-end;
            `)}
`;

const Header = styled.div`
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    align-items: center;
    align-self: flex-start;
    width: 100%;
    padding: 16px 24px;
    z-index: 3;

    ${breakpoints.down('xs')} {
        grid-template-columns: 1fr;
        flex-direction: column-reverse;
        gap: 2em;

        > ${HeaderBlock} {
            justify-content: center;
        }
    }
`;

const LogoutButton = styled(Button)`
    margin-left: 12px;
    color: ${white[100]};
`;

const PolicyLink = styled.div`
    width: 100%;
    text-align: center;

    > a {
        display: inline-block;
        margin: 0 auto;
        color: rgba(255, 255, 255, 0.8);
        font-size: 1rem;

        ${breakpoints.down('xs')} {
            margin-top: auto;
            margin-bottom: 2em;
            padding-top: 2em;
        }

        &:hover {
            text-decoration: underline;
        }
    }
`;

const PlatformPage = (): React.ReactElement => {
    const { t } = useTranslation(['common']);

    const apolloClient = useApolloClient();
    const navigate = useNavigate();

    const { currentRegionalCenter, loading: currentRegionalCenterLoading } =
        useCurrentRegionalCenter();

    const { regionalCenters, loading: regionalCentersLoading } = useRegionalCenters();
    const { platformsAccessList, loading: platformsAccessListLoading } = usePlatformsAccessList();

    const [logout, { loading: logoutLoading }] =
        useMutation<RevokeTokenMutationType, RevokeTokenMutationVariables>(RevokeTokenMutation);

    const handleClickLogout = async (): Promise<void> => {
        const refreshToken = TokenSession.getCurrentSession().getRefreshToken().getToken();

        if (!refreshToken) {
            return;
        }

        try {
            await logout({ variables: { refreshToken } });
        } catch (e) {
            console.log(e);
        } finally {
            TokenSession.destroyCurrentSession();
            await apolloClient.clearStore();
            await navigate(routes.login.path, { replace: true });
        }
    };

    return (
        <Root>
            <Wrapper>
                <Header>
                    <HeaderBlock align='left'>
                        <ChooseRegionalCenter
                            loading={currentRegionalCenterLoading || regionalCentersLoading}
                            currentRegionalCenter={currentRegionalCenter}
                            regionalCenters={regionalCenters}
                        />
                    </HeaderBlock>

                    <HeaderBlock align='right'>
                        <DownloadCertificate logoutLoading={logoutLoading} />

                        <ChangeLanguage color='white' />

                        <LogoutButton
                            variant='text'
                            endIcon={<LogoutIcon />}
                            loading={logoutLoading}
                            onClick={handleClickLogout}
                        >
                            {t('common:actions.exit')}
                        </LogoutButton>
                    </HeaderBlock>
                </Header>

                <PlatformList list={platformsAccessList} loading={platformsAccessListLoading} />

                <PolicyLink>
                    <a
                        href='https://storage.yandexcloud.net/avangard-static/obrabotka-personalnih-dannih.pdf'
                        target='_blank'
                        rel='noreferrer'
                    >
                        {t('common:blocks.policy_term')}
                    </a>
                </PolicyLink>
            </Wrapper>
        </Root>
    );
};

export { PlatformPage };
