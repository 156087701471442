import { BaseEntity, Entity as DecribeEntity, Relation, RelationList } from '@lib/entity';

import { RegionEntityList, LocationEntity } from '@modules/shared/entities';

import type { RegionEntity } from '@modules/shared/entities';
import type { RegionalCenter } from '@modules/types/graphql';

export type Entity = RegionalCenter | null;

@DecribeEntity('RegionalCenterEntity')
class RegionalCenterEntity extends BaseEntity {
    id: string;
    name: string;

    @RelationList(() => RegionEntityList)
    regions: RegionEntity[];

    @Relation(() => LocationEntity)
    location: LocationEntity;

    constructor(entity: Entity) {
        super(entity);

        this.id = entity?.id ?? '';
        this.name = entity?.name ?? '';
    }

    regionIds() {
        if (this.regions.length === 0) {
            return [];
        }

        const result = this.regions.map(region => region.id);

        return result;
    }

    regionsToString(firstRegionsCount?: number) {
        let regions = [...this.regions];

        if (regions.length === 0) {
            return '';
        }

        if (firstRegionsCount && regions.length >= firstRegionsCount) {
            regions = regions.slice(0, firstRegionsCount);
        }

        const result = regions.reduce((carry, region, idx) => {
            if (region.name) {
                carry += region.name;
            }

            if (idx === regions.length - 1) {
                return carry;
            }

            carry += ', ';

            return carry;
        }, '');

        return result;
    }
}

export { RegionalCenterEntity };
