import styled from 'styled-components';

import { Loader } from './loader';

const PlatformLoader = styled(Loader)`
    width: initial;
    background-color: transparent;
`;

export { PlatformLoader };
