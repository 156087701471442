import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { Location } from '@modules/types/graphql';

export type Entity = Location | null;

@DecribeEntity('LocationEntity')
class LocationEntity extends BaseEntity {
    id: string;
    name: string;

    constructor(entity: Entity) {
        super(entity);

        this.id = entity?.id ?? '';
        this.name = entity?.name ?? '';
    }
}

export { LocationEntity };
