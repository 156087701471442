import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { white } from '@avangard/ui/colors';
import { breakpoints, isRtl } from '@avangard/ui/utils';

import { TokenSession } from '@lib/token';
import { REGIONAL_CENTERS_DOMAIN } from '@config/environment';
import {
    regionalCentersBackground,
    regionalCentersLogo,
} from '@modules/auth/helpers/assets/images';

const Logo = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    img {
        object-fit: contain;
        height: 100%;
    }
`;

const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: ${white[100]};
`;

const Text = styled.span`
    width: 100%;
    font-size: 1.4rem;
    text-align: center;
`;

const Root = styled.div<{ background: string; disabled?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    position: relative;
    max-width: 916px;
    width: 100%;
    margin-bottom: 40px;
    padding-inline-end: 24px;
    border-radius: 5px;
    overflow: hidden;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    transform: scale(1);

    &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: ${p => `url(${p.background})`};

        ${isRtl`
            left: initial;
            right: 0;
        `}
    }

    &:hover {
        transform: scale(1.05);
        filter: brightness(130%);
    }

    ${p =>
        !!p.disabled &&
        css`
            opacity: 0.8;
            cursor: default;
            pointer-events: none;

            &:before {
                filter: grayscale(100%) brightness(50%);
            }
        `}

    ${breakpoints.down(1600)} {
        max-width: 736px;
    }

    ${breakpoints.down(752)} {
        justify-content: center;
        max-width: 360px;
        margin-bottom: 10px;
        padding: 16px;
    }

    ${breakpoints.down(600)} {
        max-width: 320px;
        margin: 12px 0;
        padding: 0;

        > * {
            width: 100%;
        }

        ${Text} {
            padding-bottom: 24px;
        }
    }
`;

const PlatformRegionalCard = () => {
    const { i18n, t } = useTranslation(['common']);

    const handleClick = async () => {
        const redirectTo = REGIONAL_CENTERS_DOMAIN + '/' + i18n.language;

        const accessToken = TokenSession.getCurrentSession().getAccessToken().getToken();
        const refreshToken = TokenSession.getCurrentSession().getRefreshToken().getToken();

        if (!accessToken || !refreshToken || !redirectTo) {
            return;
        }

        TokenSession.setCurrentSession({ accessToken, refreshToken });
        window.location.href = redirectTo;
    };

    return (
        <Root background={regionalCentersBackground} onClick={handleClick}>
            <Logo>
                <img src={regionalCentersLogo} alt='regional-centers-logo' />
            </Logo>

            <Main>
                <Text>{t('common:blocks.course.regional_centers')}</Text>
            </Main>
        </Root>
    );
};

export { PlatformRegionalCard };
