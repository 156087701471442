import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { white, red } from '@avangard/ui/colors';
import { breakpoints, isRtl } from '@avangard/ui/utils';

import { RouteMenuLocationEnum } from '@config/routes';
import { getMenuRoutes, Link } from '@lib/routing';
import { SidebarLogo } from '@modules/layout/icons';
import { useCurrentUser } from '@modules/auth/hooks';

import type { LinkGetProps } from '@reach/router';

type SidebarProps = {
    open: boolean;
    onClose?: () => void;
};

const itemActiveStyles = {
    color: white[100],
    backgroundColor: red[100],
};

const Root = styled.aside<Pick<SidebarProps, 'open'>>`
    position: fixed;
    top: 0;
    left: 0;
    width: 230px;
    height: 100%;
    background-image: url('/images/main-background.jpg');
    background-size: contain;
    z-index: 1;
    transition: 0.23s ease-in-out;

    ${breakpoints.down('sm')} {
        padding-top: 64px;
        padding-bottom: 74px;
        transform: ${p => (p.open ? 'translateX(0)' : 'translateX(-230px)')};
    }

    ${breakpoints.down('xs')} {
        padding-top: 44px;
    }
`;

const Logo = styled.div`
    width: 100%;
`;

const List = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;
    > * {
        &:not(:last-of-type) {
            margin-bottom: 8px;
        }
    }

    ${breakpoints.down('sm')} {
        overflow-x: hidden;
        overflow-y: auto;
        flex: 1 1 auto;
        max-height: calc(100% - 70px);
    }
`;

const ListItem = styled(Link)`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 16px 0 24px;
    color: ${white[60]};

    &:hover {
        color: ${white[100]};
    }
`;

const ListItemIcon = styled.div`
    display: flex;
    width: 16px;
    height: 16px;
`;

const ListItemContent = styled.div`
    width: calc(100% - 28px);
    margin-inline-start: 12px;
    font-size: 1.4rem;
    line-height: 16px;
`;

const Support = styled.div`
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 20px;
    color: ${white[100]};
    font-size: 1.4rem;
    line-height: 20px;

    ${isRtl`
        left: initial;
        right: 0;
    `}

    a {
        color: inherit;
    }
`;

const Sidebar = (props: SidebarProps): React.ReactElement => {
    const { open } = props;

    const { t } = useTranslation();

    const { currentUser } = useCurrentUser();

    const menuRoutes = getMenuRoutes(RouteMenuLocationEnum.sidebar, {
        forRoles: currentUser.roles,
    });

    const isCurrent = React.useCallback(
        (linkProps: LinkGetProps) => ({
            style: linkProps.isCurrent || linkProps.isPartiallyCurrent ? itemActiveStyles : {},
        }),
        [],
    );

    return (
        <Root open={open}>
            <Logo>
                <SidebarLogo />
            </Logo>

            {menuRoutes.length ? (
                <List>
                    {menuRoutes.map(menuRoute => {
                        const Icon = menuRoute.menuIcon;

                        return (
                            <ListItem key={menuRoute.name} to={menuRoute.path} getProps={isCurrent}>
                                <ListItemIcon>
                                    {Icon ? <Icon style={{ fontSize: 16 }} /> : null}
                                </ListItemIcon>

                                <ListItemContent>
                                    {t(menuRoute.menuTitle ?? menuRoute.title)}
                                </ListItemContent>
                            </ListItem>
                        );
                    })}
                </List>
            ) : null}

            <Support>
                <p>
                    {t('common:support.ask')} <br />
                    <a href='mailto:info@avangard-online.ru'>info@avangard-online.ru</a>
                </p>
            </Support>
        </Root>
    );
};

export { Sidebar };
