import React from 'react';

import { Redirect } from '@lib/routing';
import { routes } from '@config/routes';

const IndexPage = (): React.ReactElement | null => {
    return <Redirect noThrow to={routes.platform.path} />;
};

export { IndexPage };
