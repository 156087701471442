import React from 'react';

import { routes } from '@config/routes';
import { Redirect } from '@lib/routing';
import { useLocationQuery } from '@modules/layout/hooks';
import { ResetPasswordForm } from '@modules/auth/organisms';

const ResetPasswordPage = (): React.ReactElement | null => {
    const [query] = useLocationQuery();

    if (!query.token) {
        return <Redirect noThrow to={routes.index.path} />;
    }

    return <ResetPasswordForm />;
};

export { ResetPasswordPage };
