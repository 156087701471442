import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { PlatformAccessItem, Lap } from '@modules/types/graphql';

export type Entity = Partial<PlatformAccessItem> | null;

@DecribeEntity('PlatformAccessItemEntity')
class PlatformAccessItemEntity extends BaseEntity {
    platform: string;
    isAvailable: boolean;
    lap?: Lap;

    constructor(lapAccess: Entity) {
        super(lapAccess);

        this.platform = lapAccess?.platform ?? '';
        this.isAvailable = lapAccess?.isAvailable ?? false;
        this.lap = lapAccess?.lap ?? undefined;
    }
}

export { PlatformAccessItemEntity };
