import React from 'react';
import { makeStyles, TableSortLabel as MuiTableSortLabel } from '@material-ui/core';
import { concatenateClasses } from '@avangard/ui/utils';

import type { TableSortLabelProps as MuiTableSortLabelProps } from '@material-ui/core';

type TableSortLabelProps = MuiTableSortLabelProps;

const useTableSortLabelStyles = makeStyles({
    icon: {
        opacity: 0.3,
    },
});

const TableSortLabel = (props: TableSortLabelProps) => {
    const { classes, ...otherProps } = props;

    const tableSortLabelClasses = concatenateClasses(useTableSortLabelStyles(), classes);

    return <MuiTableSortLabel classes={tableSortLabelClasses} {...otherProps} />;
};

export { TableSortLabel };
