import React from 'react';

import { TokenSession } from '@lib/token';
import { Redirect } from '@lib/routing';
import { routes } from '@config/routes';
import { useCurrentUser } from '@modules/auth/hooks';

import type { Route } from '@config/routes';

const PrivateRoute = (props: Route): React.ReactElement | null => {
    const { roles, Component, LayoutComponent, ...otherProps } = props;

    const hasTokens = TokenSession.getCurrentSession().hasTokens();

    const {
        currentUser,
        loading: userLoading,
        error: userError,
    } = useCurrentUser({
        skip: !hasTokens,
    });

    if (userLoading) {
        return null;
    }

    // TODO: remade redirect depends on error
    if (userError || !currentUser.authenticated()) {
        return <Redirect noThrow to={routes.login.path} />;
    }

    if (!currentUser.hasAccess(roles)) {
        return <Redirect noThrow to={routes.index.path} />;
    }

    return (
        <LayoutComponent pageTitle={otherProps.title} withBackIcon={otherProps.withBackIcon}>
            <Component {...otherProps} />
        </LayoutComponent>
    );
};

export { PrivateRoute };
