import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Tabs } from '@avangard/ui/core';
import { white } from '@avangard/ui/colors';

import type { TabsProps } from '@avangard/ui/core';

const useTabsStyles = makeStyles({
    root: {
        width: '100%',
        minHeight: 46,
        marginBottom: 24,
        borderBottom: `1px solid ${white[70]}`,
    },

    indicator: {
        height: 2,
    },
});

const TabsNavigation = (props: TabsProps): React.ReactElement => {
    const classes = useTabsStyles();

    return <Tabs classes={classes} {...props} />;
};

export { TabsNavigation };
