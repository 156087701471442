import React from 'react';
import styled from 'styled-components';

import { ReactComponent as EmptyListIcon } from './source/empty-list.svg';
import { Root } from './shared/styled';

import type { IconProps } from './shared/types';

const StyledRoot = styled(Root)`
    height: 40px;

    .icon-ellipse {
        fill: #f5f5f5;
    }

    .icon-g {
        stroke: #d9d9d9;
    }

    .icon-path {
        fill: #fafafa;
    }
`;

const EmptyList = (props: IconProps): React.ReactElement => {
    return (
        <StyledRoot {...props}>
            <EmptyListIcon />
        </StyledRoot>
    );
};

export { EmptyList };
