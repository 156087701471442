import { gql } from '@apollo/client';

export const GetRegionalCenterCollectionQuery = gql`
    query getRegionalCenterCollection {
        getRegionalCenterCollection {
            items {
                id
                name
                regions {
                    id
                    name
                }
                location {
                    id
                    name
                }
            }
        }
    }
`;
