import type { LoginMutationVariables } from '@modules/types/graphql';

export type LoginFormValues = {
    login: string;
    password: string;
};

class LoginRequest {
    login: LoginMutationVariables['login'];
    password: LoginMutationVariables['password'];

    constructor(values: LoginFormValues) {
        this.login = values.login;
        this.password = values.password;
    }
}

export { LoginRequest };
