import React from 'react';
import { makeStyles, TableCell as MuiTableCell } from '@material-ui/core';
import { white, black } from '@avangard/ui/colors';
import { concatenateClasses } from '@avangard/ui/utils';

import type { Theme, TableCellProps as MuiTableCellProps } from '@material-ui/core';

type TableCellProps = MuiTableCellProps;

const useTableCellStyles = makeStyles<Theme, Pick<TableCellProps, 'width'>>({
    root: p => ({
        width: p.width ? p.width + 'px' : '150xp',
        padding: '8px 16px',
        fontSize: '1.4rem',
        transition: 'background-color .2s',
        borderBottom: `1px solid ${white[70]}`,
    }),
    head: {
        color: black[80],
        backgroundColor: white[90],
    },
    body: {
        backgroundColor: white[100],
    },
});

const TableCell = (props: TableCellProps): React.ReactElement => {
    const { classes, width, ...otherProps } = props;

    const tableCellClasses = concatenateClasses(useTableCellStyles({ width }), classes);

    return <MuiTableCell classes={tableCellClasses} {...otherProps} />;
};

export { TableCell };
