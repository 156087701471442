import React from 'react';

import { routes } from '@config/routes';
import { Redirect } from '@lib/routing';
import { useCurrentUser } from '@modules/auth/hooks';
import { ForgotPasswordForm } from '@modules/auth/organisms';

const ForgotPasswordPage = (): React.ReactElement => {
    const { currentUser } = useCurrentUser();

    if (currentUser.authenticated()) {
        return <Redirect noThrow to={routes.index.path} />;
    }

    return <ForgotPasswordForm />;
};

export { ForgotPasswordPage };
