import { gql } from '@apollo/client';

import { LoginReplyFragment } from '../fragments';

export const ResetPasswordMutation = gql`
    mutation ResetPassword($token: String!, $newPassword: String!) {
        resetPassword(token: $token, newPassword: $newPassword) {
            ...LoginReply
        }
    }
    ${LoginReplyFragment}
`;
