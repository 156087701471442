import React from 'react';
import styled from 'styled-components';
import { ArrowBack } from '@material-ui/icons';

type HeaderButtonBackProps = {
    onClick: () => void;
};

const BackIcon = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 2px;
    padding-right: 16px;
    cursor: pointer;
`;

const arrowBackIcon = <ArrowBack style={{ fontSize: 24 }} />;

const HeaderButtonBack = (props: HeaderButtonBackProps) => {
    const { onClick } = props;

    return <BackIcon onClick={onClick}>{arrowBackIcon}</BackIcon>;
};

export { HeaderButtonBack };
