import { gql } from '@apollo/client';

export const GetLapsWithCertificatesQuery = gql`
    query GetLapsWithCertificates {
        getLapsWithCertificates {
            certificatesUrl

            lap {
                id
                name
                from
                to

                course {
                    id
                    name
                }
            }
        }
    }
`;
