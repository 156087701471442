import React from 'react';
import Image from 'react-image-enlarger';
import { createGlobalStyle } from 'styled-components';

type ZoomImageProps = {
    src: string;
    alt?: string;
    style?: Record<string, any>;
    className?: string;
};

const GlobalImageStyles = createGlobalStyle`
    .EnlargedImage__clone {
        padding: 12px;
    }
`;

const ZoomImage = (props: ZoomImageProps): React.ReactElement => {
    const { alt = '', ...otherProps } = props;

    const [zoomed, setZoomed] = React.useState(false);

    const handleClickOpen = (): void => setZoomed(true);

    const handleClickClose = (): void => setZoomed(false);

    return (
        <>
            <GlobalImageStyles />
            <Image
                zoomed={zoomed}
                onClick={handleClickOpen}
                onRequestClose={handleClickClose}
                {...otherProps}
            />
        </>
    );
};

export { ZoomImage };
