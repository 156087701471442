import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useLocation } from '@reach/router';
import { InputAdornment } from '@material-ui/core';
import { Person } from '@material-ui/icons';
import { black } from '@avangard/ui/colors';
import { TextField, Button } from '@avangard/ui/core';

import { routes } from '@config/routes';
import { useNavigate, createRouteUrl } from '@lib/routing';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { SendForgotPasswordMutation } from '@modules/auth/graphql';

import type {
    SendForgotPasswordMutationType,
    SendForgotPasswordMutationVariables,
} from '@modules/types/graphql';

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    width: 100%;
`;

const Header = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    > * {
        width: 100%;
    }
`;

const Title = styled.h3`
    color: ${black[80]};
    font-size: 2.3rem;
    font-weight: 500;
`;

const Main = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 24px;
`;

const StyledForm = styled(Form)`
    width: 100%;
`;

const BackButton = styled.div`
    width: 100%;
    margin-top: 24px;
`;

const RowWithMargin = styled.div`
    width: 100%;

    * + & {
        margin-top: 24px;
    }

    & + & {
        margin-top: 12px;
    }
`;

const personIcon = <Person style={{ fontSize: 16 }} />;

const ForgotPasswordForm = (): React.ReactElement => {
    const { t } = useTranslation(['common', 'errors', 'auth']);

    const location = useLocation();
    const navigate = useNavigate();

    const [sentEmail, setSentEmail] = React.useState<string | undefined>(undefined);

    const locationState = { ...(location.state as Record<string, any>) };

    const [sendForgotPassword] = useMutation<
        SendForgotPasswordMutationType,
        SendForgotPasswordMutationVariables
    >(SendForgotPasswordMutation);

    const handleClickRedirectToLogin = (): void => {
        const route = createRouteUrl(routes.login);

        navigate(route);
    };

    if (sentEmail) {
        return (
            <Root>
                <Title>{t('auth:blocks.titles.forgot_password_sent', { sentEmail })}</Title>

                <BackButton>
                    <Button fullWidth onClick={handleClickRedirectToLogin}>
                        {t('common:blocks.common.return_to_index')}
                    </Button>
                </BackButton>
            </Root>
        );
    }

    return (
        <Root>
            <Header>
                <Title> {t('auth:blocks.titles.forgot_password')}</Title>
            </Header>

            <Main>
                <ExtendedFormik
                    validationSchema={yup.object({
                        login: yup.string().required(t('errors:login.required') ?? ''),
                    })}
                    initialValues={{
                        login: locationState.login ?? '',
                    }}
                    onSubmit={async values => {
                        try {
                            const { data: sendForgotPasswordData } = await sendForgotPassword({
                                variables: {
                                    login: values.login,
                                },
                            });

                            const result = sendForgotPasswordData?.sendForgotPassword;

                            if (!result?.email) {
                                throw new Error(t('errors:validations.general_error') ?? '');
                            }

                            setSentEmail(result.email);
                        } catch (e) {
                            console.log(e);
                            throw e;
                        }
                    }}
                >
                    {formikProps => {
                        const { values, errors, handleChange, handleBlur, isSubmitting } =
                            formikProps;

                        return (
                            <StyledForm>
                                <TextField
                                    fullWidth
                                    id='login'
                                    label={t('common:form.labels.username')}
                                    placeholder={t('common:form.placeholder.username') ?? ''}
                                    value={values.login}
                                    error={!!errors.login}
                                    helperText={errors.login}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                {personIcon}
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                <RowWithMargin>
                                    <Button fullWidth type='submit' loading={isSubmitting}>
                                        {t('common:actions.recovery')}
                                    </Button>
                                </RowWithMargin>

                                <RowWithMargin>
                                    <Button
                                        fullWidth
                                        variant='text'
                                        type='button'
                                        disabled={isSubmitting}
                                        onClick={handleClickRedirectToLogin}
                                    >
                                        {t('common:blocks.common.return_to_auth_page')}
                                    </Button>
                                </RowWithMargin>
                            </StyledForm>
                        );
                    }}
                </ExtendedFormik>
            </Main>
        </Root>
    );
};

export { ForgotPasswordForm };
