import styled from 'styled-components';
import { breakpoints } from '@avangard/ui/utils';

import { RegionalCenterEntity } from '@modules/regional-center/entities';
import { RegionalCenterRegionsInfo } from '@modules/regional-center/atoms';

type RegionalCenterProps = {
    currentRegionalCenter: RegionalCenterEntity;
};

const Title = styled.div`
    min-width: 200px;
    color: #fff;
    font-size: 1.4rem;

    ${breakpoints.down('xs')} {
        text-align: center;
    }
`;

const RegionalCenter = (props: RegionalCenterProps) => {
    const { currentRegionalCenter } = props;

    return (
        <Title>
            <p>{currentRegionalCenter.name}</p>

            <RegionalCenterRegionsInfo regionalCenter={currentRegionalCenter} />
        </Title>
    );
};

export { RegionalCenter };
